import { get } from "../Web.request";

export const getAttendanceApiListHandler = (
  startDate,
  endDate,
  search,
  page,
  categoryValue,
  personType,
  standardType
) => {
  return get(
    `${process.env.REACT_APP_API_URL
    }/users/attendance_data?start_date=${startDate}&end_date=${endDate}&page=${page}&alert_type_name=${categoryValue}&standard_name=${standardType ? standardType : ""
    }&person_name=${personType ? personType : ""}`
  );
};

export const getAttendanceApiRoomListHandler = (
  startDate,
  endDate,
  search,
  page,
  categoryValue,
  personType,
  standardType
) => {
  return get(`${process.env.REACT_APP_API_URL}/users/attendance_for_student_educator?start_date=${startDate}&end_date=${endDate}&page=${page}&alert_type_name=${categoryValue}&standard_name=${standardType ? standardType : ""}&person_name=${personType ? personType : ""}`);
};
