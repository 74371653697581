import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { AutoComplete, DateRange, Input } from "../../components/commons";
import { personList } from "../../helper/Helper";
import { useEffect, useState } from "react";
import { nonAttendanceApiListHandler } from "../../Service/NonAttendance.service";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import moment from "moment";
import SubHeader from "../../components/commons/SubHeader";

export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nonAttendanceList, setNonAttendList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getNonAttendanceListHandler(startDate, endDate, page);
  }, [startDate, endDate]);

  const DataRangeHandler = (value) => {
    setStartDate(value.formDate);
    setEndDate(value.toDate);
  };

  const pageChangeHandler = (data) => {
    setPage(data);
    getNonAttendanceListHandler(startDate, endDate, data);
  };

  const getNonAttendanceListHandler = async (startDate, endDate, page) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } =
        await nonAttendanceApiListHandler(startDate, endDate, page);
      if (success) {
        setNonAttendList(data);
        setDataCount(count);
      } else {
        setDataCount(0);
        setNonAttendList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack flexDirection={"row"} gap={2} mb={2}>
        {/* <AutoComplete options={personList} placeholder='Select Person' /> */}
        {/* <DateRange dataHandler={DataRangeHandler} /> */}
        <Typography className="white-box fontsize">
          Date:-
        </Typography>
        <Input
          placeholder="Select Date"
          type="date"
          onChange={(event) => setStartDate(event.target.value)}
        />
        {/* <SubHeader Module={"NONATTENDANCE"} buttonName={"export as Csv"} /> */}
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>SFI</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Entry</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={5} />
            ) : nonAttendanceList && nonAttendanceList.length > 0 ? (
              nonAttendanceList.map((response, index) => {
                const {
                  alert_type,
                  alert_type_name,
                  created_at,
                  entry_time,
                  Comment,
                  comment,
                  category,
                  day,
                  Entry,
                  Category,
                  date,
                  exit_time,
                  id,
                  profile_image,
                  username,
                  user_image,
                  Image,
                  standard,
                  standard_name,
                } = response;
                return (
                  <TableRow
                    key={index}
                    className="MuiReportTable-root"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{username ? username : "-"}</TableCell>
                    <TableCell>
                      <img
                        src={user_image ? user_image : ""}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>{date ? date : "-"}</TableCell>
                    <TableCell>{entry_time ? entry_time : "-"}</TableCell>
                    <TableCell>{category ? category : "-"}</TableCell>
                    <TableCell>{comment ? comment : "-"}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
        perPage={8}
      />
    </>
  );
}
