import React, { useEffect, useState } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  // Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button, Input, Select } from "../../components/commons";
import { classList } from "../../helper/Helper";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getStandardAllList,
  getMasetEnrollHours,
} from "../../Service/Standard.service";
import { useFormik } from "formik";
import { educatorPersonIv } from "../../helper/intialValues";
import { educatorPersonValidation } from "../../helper/Validation";
import {
  modificationToPerson,
  updateModificationApihandler,
} from "../../Service/modificationPerson";
import {
  errorNotification,
  successNotification,
} from "../../helper/Notification";
import CSelect from "../../components/commons/CSelect";
import { Unknown } from "../../helper/Constant";
import { IoClose } from "react-icons/io5";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "../../components/commons";
function EducatorPerson({
  optOutFlag,
  optoutHandler,
  categoryList,
  categoryValue,
  handleCategoryChange,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state;
  const [standardList, setStandardList] = useState([]);
  const [standard, setStandard] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [rostedHours, setrostedHours] = useState("");

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  useEffect(() => {
    getStandardListHandler();
  }, []);

  const closeFlagHandler = () => {
    navigate(`/modifications`);
  };

  useEffect(() => {
    if (navigationData?.editFlage) {
      getDataHandler(navigationData?.responseData);
    } else {
      formik.resetForm();
    }
  }, []);

  const getDataHandler = (data) => {
    setStandard(data?.response?.ROOM?.id);
    formik.setFieldValue("standard_id", data?.response?.ROOM?.id);
    // formik.setFieldValue("rosted_hours", data?.response?.rosted_hours);
    // setrostedHours(data?.response?.rosted_hours)
    const enrollData =
      data?.response?.enrollment_hours.length > 0 &&
      data?.response?.enrollment_hours;
    if (enrollData.length > 0) {
      const data = enrollData.map((response) => {
        return {
          day: response.day,
          entry_time: response.entry_time,
          exit_time: response.exit_time,
        };
      });
      enrollmentHoursHandler(data);
    }
  };

  const enrollmentHoursHandler = (data) => {
    formik.setFieldValue("enrollment_data", data);
  };

  const standardHandler = (value) => {
    setStandard(value);
    formik.setFieldValue("standard_id", value);
  };

  const handleChange = (e, index, field) => {
    const updatedEnrollmentData = formik.values.enrollment_data;
    updatedEnrollmentData[index][field] = e.target.value;
    formik.setFieldValue("enrollment_data", updatedEnrollmentData);
  };


  const addEducatorPersonHandler = async () => {
    try {
      setLoading(true);

      const { data, message, success } = navigationData?.editFlage
        ? await updateModificationApihandler(
          navigationData.personId,
          formik.values
        )
        : await modificationToPerson(navigationData.personId, formik.values);
      if (success) {
        navigate("/modifications");
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: educatorPersonIv,
    validationSchema: educatorPersonValidation,
    onSubmit: addEducatorPersonHandler,
  });

  const getStandardListHandler = async () => {
    try {
      const { data, message, success } = await getStandardAllList();
      if (success) {
        setStandardList(data);
      } else {
        setStandardList([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getMasterDataEnrolHours = async () => {
    const { count, data, message, success } = await getMasetEnrollHours();
    if (success) {
      formik.setFieldValue("enrollment_data", navigationData && navigationData?.responseData && navigationData?.responseData?.response && navigationData?.responseData?.response?.enrollment_hours ? navigationData?.responseData?.response?.enrollment_hours : data);

    }
  };

  useEffect(() => {
    formik.setFieldValue("opt_out", optOutFlag);
    getMasterDataEnrolHours();
  }, [optOutFlag]);

  const manageRemoveTime = (index) => {
    const updatedEnrollmentData = formik.values.enrollment_data;
    const indexData = updatedEnrollmentData[index];
    indexData.entry_time = '';
    indexData.exit_time = '';
    updatedEnrollmentData[index] = indexData;
    formik.setFieldValue("enrollment_data", updatedEnrollmentData);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Stack flexDirection={"row"} gap={7}>
            <Box sx={{ display: "flex" }}>
              <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
                <Box sx={{ display: "flex" }}>
                  <IoClose className="closeicon"
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "deleteChild",
                        para: "Are you sure want to delete ?",
                        head: "Delete Educator",
                        id: navigationData.personId,
                        name: "STAFF",
                      })} />
                  <img
                    src={
                      navigationData?.personImage
                        ? navigationData?.personImage
                        : ""
                    }
                    alt=""
                    width={550}
                    height={600}
                  />
                </Box>
                <Typography variant="h2" className="Gilroy_Bold">
                  {navigationData?.personName ? navigationData?.personName : "-"}
                </Typography>
              </Stack>
            </Box>
            <Stack gap={3}>
              <Stack gap={3} mt={3}>
                <Stack sx={{ width: "100%", mt: 5 }}>
                  <Box sx={{ width: "100%" }}>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      gap={3}
                    >
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"start"}
                            >
                              <Typography
                                fontSize={{ xs: "18px", sm: "38px" }}
                                fontWeight={"600"}
                              >
                                Opt Out :
                              </Typography>
                              &nbsp; &nbsp; &nbsp;
                              <Switch
                                checked={optOutFlag}
                                onChange={(event) =>
                                  optoutHandler(event.target.checked)
                                }
                                color="secondary"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              flexDirection={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              gap={2}
                            >
                              <Typography
                                fontSize={{ xs: "18px", sm: "38px" }}
                                fontWeight={"600"}
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                Category :
                              </Typography>
                              <CSelect
                                style={{ width: "300px" }}
                                selectHead="Select Category"
                                selectList={categoryList}
                                value={categoryValue}
                                onChange={handleCategoryChange}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Typography
                  fontSize={{ xs: "18px", sm: "38px" }}
                  fontWeight={"600"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Room :
                </Typography>
                <Select
                  style={{ maxWidth: "300px" }}
                  selectHead="Select Room"
                  selectList={standardList}
                  value={formik.values.standard_id}
                  onChange={(e) => formik.setFieldValue("standard_id", e.target.value)}
                  name="standard_id"
                />
                {errorFlag && formik.errors.standard_id && (
                  <FormHelperText>{formik.errors.standard_id}</FormHelperText>
                )}
                {/* <Select
                  sx={{ width: "300px" }}
                  displayEmpty
                  value={standard}
                  onChange={(event) => standardHandler(event.target.value)}
                >
                  {standardList && standardList.length > 0 ? (
                    standardList.map((response) => {
                      return (
                        <MenuItem value={response.id}>{response.name}</MenuItem>
                      );
                    })
                  ) : (
                    <h3> No room found</h3>
                  )}
                </Select> */}
                {/* {errorFlag && formik.errors.standard_id && (
                  <FormHelperText>{formik.errors.standard_id}</FormHelperText>
                )} */}
              </Stack>
              {/* <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Typography
                  fontSize={{ xs: "18px", sm: "38px" }}
                  fontWeight={"600"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Rostered Hours:-
                </Typography>
                <Input
                  sx={{ width: "300px" }}
                  placeholder="40 Per Week"
                  value={rostedHours}
                  // formik={formik}
                  name="rosted_hours"
                  onChange={(e) => { setrostedHours(e.target.value); formik.setFieldValue("rosted_hours", e.target.value); }}
                />
              </Stack> */}
              <Grid item xs={12}>
                <Stack
                  flexDirection={"row"}
                  gap={{ xs: 2 }}
                  mt={3}
                  justifyContent={"center"}
                >
                  <Button
                    buttonName={"Save"}
                    loading={loading}
                    disabled={loading}
                    onClick={() => [formik.handleSubmit(), setErrorFlag(true)]}
                  />
                  <Button
                    buttonName="Exit"
                    color="white"
                    onClick={() => navigate("/modifications")}
                  />
                </Stack>
              </Grid>
            </Stack>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "flex-end" }}>
              <Box sx={{ width: "min-content" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={"600"}>
                      Enrolment Hours
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table className="add_person_hours_education">
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell>Entry</TableCell>
                          <TableCell>Exit</TableCell>
                          {/* <th></th> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log('formik.values.enrollment_data', formik.values.enrollment_data)}
                        {formik.values.enrollment_data.map((response, index) => {
                          return (
                            <TableRow>
                              <TableCell>{response.day}</TableCell>
                              <TableCell>
                                <input
                                  type="time"
                                  value={response.entry_time}
                                  onChange={(e) =>
                                    handleChange(e, index, "entry_time")
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  type="time"
                                  value={response.exit_time}
                                  onChange={(e) =>
                                    handleChange(e, index, "exit_time")
                                  }
                                />
                              </TableCell>
                              {/* <TableCell>
                                <Button
                                  buttonName={
                                    <DeleteIcon style={{ fontSize: "35px" }} />
                                    // "Delete"
                                  }
                                  color="red"
                                  onClick={() => manageRemoveTime(index)}
                                />
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Stack>
        </Grid>

      </Grid>

      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default EducatorPerson;
