import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Autocomplete,
  TextField,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { DateRange, Input, Select } from "../../components/commons";
import { categoryList, classList, personList } from "../../helper/Helper";
import { useEffect, useState } from "react";
import { getAttendanceApiListHandler } from "../../Service/Attendances.service";
import { newPersonListHandler } from "../../Service/personList.service";
import { getStandardAllList } from "../../Service/Standard.service";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import SubHeader from "../../components/commons/SubHeader";

export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState([]);
  const [attendancesList, setAttendancesList] = useState([]);

  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [personType, setPersonType] = useState("");
  const [standardType, setStandardType] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  // AutoComplete

  const [personList, setPersonList] = useState([]);
  const [selectPerson, setSelectPerson] = useState("");

  const [standardList, setStandardList] = useState([]);
  const [standard, setStandard] = useState("");
  const [tableHeaders, setTableHeaders] = useState([]);

  useEffect(() => {
    getAttendancesListHandler(
      startDate,
      endDate,
      search,
      page,
      categoryValue,
      personType,
      standardType
    );
  }, [startDate, endDate, page, categoryValue, personType, standardType]);

  const getAttendancesListHandler = async (
    startDate,
    endDate,
    search,
    page,
    categoryValue,
    personType,
    standardType
  ) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage, others } =
        await getAttendanceApiListHandler(
          startDate,
          endDate,
          search,
          page,
          categoryValue,
          personType,
          standardType
        );
      if (success) {
        // tableHeaders,
        setTableHeaders(others);
        setDataCount(count);
        setAttendancesList(data);
      } else {
        setDataCount(0);
        setAttendancesList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStandardListHandler();
    getPersonListHandler();
  }, []);

  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };

  const dataRanageHandler = (value) => {
    setStartDate(value.formDate);
    setEndDate(value.toDate);
  };

  const pageChangeHandler = (data) => {
    setPage(data);
    getAttendancesListHandler(
      startDate,
      endDate,
      search,
      data,
      categoryValue,
      personType,
      standardType
    );
  };

  const getStandardListHandler = async () => {
    try {
      const { data, message, success } = await getStandardAllList();
      if (success) {
        if (data && data.length > 0) {
          const standardData = data.map((response, index) => {
            return {
              label: response.name,
              id: response.id,
            };
          });
          setStandardList(standardData);
        }
      } else {
        setStandardList([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPersonListHandler = async () => {
    try {
      const { count, data, message, success, totalPage } =
        await newPersonListHandler();
      if (success) {
        const personData =
          data &&
          data.map((res) => {
            return {
              label: res.username,
              id: res.id,
            };
          });
        setPersonList(personData);
      } else {
        setPersonList([]);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  return (
    <>
      <Stack flexDirection={"row"} gap={2} mb={2}>
        <Typography className="white-box fontsize">Date:-</Typography>
        <DateRange dataHandler={dataRanageHandler} />

        <>
          <Typography
            className="white-box"
            style={{
              justifyContent: "center",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            Category:-
          </Typography>
          <Select
            style={{ maxWidth: "300px" }}
            selectHead="Select Category"
            selectList={categoryList}
            value={categoryValue}
            onChange={handleCategoryChange}
          />
        </>
        {/* <SubHeader Module={"ATTENDANCES"} buttonName={"export as Csv"} /> */}
        {/* <SubHeader Module={"DAILYROASTER"} buttonName={"daily roaster"} /> */}
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>Date</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Entry</TableCell>
              <TableCell>Exit</TableCell>
              <TableCell>SFI</TableCell>
              <TableCell>Category</TableCell>
              {tableHeaders && tableHeaders.length > 0
                ? tableHeaders.map((item) => <TableCell>{item}</TableCell>)
                : ""}
              {/* <TableCell>Comment</TableCell> */}
              <TableCell>Child</TableCell>
              <TableCell>Guardian</TableCell>
              <TableCell>Educator</TableCell>
              <TableCell>Unknown</TableCell>
              <TableCell>Other</TableCell>
              <TableCell>Banned</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={13} />
            ) : attendancesList && attendancesList.length > 0 ? (
              attendancesList.map((response, index) => {
                const {
                  banned_count,
                  banned_data,
                  total,
                  child_count,
                  alert_type_name,
                  date,
                  day,
                  username,
                  student_username,
                  educator_count,
                  educator_data,
                  guardian_count,
                  guardian_data,
                  exit_time,
                  entry_time,
                  student_count,
                  student_data,
                  time,
                  tradie_count,
                  tradie_data,
                  unknown_person_count,
                  unknown_person_data,
                  unknown_count,
                  user_image
                } = response;
                return (
                  <TableRow
                    key={index}
                    className="MuiReportTable-root"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{date}</TableCell>
                    <TableCell><img src={user_image} height={80} /></TableCell>
                    <TableCell>{entry_time ? entry_time : "-"}</TableCell>
                    <TableCell>{exit_time ? exit_time : "-"}</TableCell>

                    <TableCell>{username ? username : "-  "}</TableCell>
                    <TableCell>
                      {alert_type_name ? alert_type_name : "-"}
                    </TableCell>
                    {tableHeaders && tableHeaders.length > 0
                      ? tableHeaders.map((item) => (
                        <TableCell>{response[item]}</TableCell>
                      ))
                      : ""}
                    {/* <TableCell>-</TableCell> */}
                    <TableCell>{child_count ? child_count : 0}</TableCell>
                    <TableCell>{guardian_count ? guardian_count : 0}</TableCell>
                    <TableCell>{educator_count ? educator_count : 0}</TableCell>
                    <TableCell>{unknown_count ? unknown_count : 0}</TableCell>
                    <TableCell>{tradie_count ? tradie_count : 0}</TableCell>
                    <TableCell>{banned_count ? banned_count : 0}</TableCell>
                    <TableCell>{total ? total : 0}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound colSpan={parseInt(tableHeaders.length) + 18} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
        perPage={8}
      />
    </>
  );
}
