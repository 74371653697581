import React, { useEffect, useState } from "react";
import { useStyles } from "../../helper/Theme";
import { useNavigate } from "react-router-dom";
import {
    Paper,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Autocomplete,
    TextField,
    Typography,
    MenuItem,
    Box,
} from "@mui/material";
import { DateRange, Input, Select } from "../../components/commons";
import { categoryList, classList, personList } from "../../helper/Helper";
import { getStandardAllList } from "../../Service/Standard.service";
import { newPersonListHandler } from "../../Service/personList.service";
import { getAttendanceApiRoomListHandler } from "../../Service/Attendances.service";
import { TableSkeleton } from "../../components/commons/Table";
import NoRecordFound from "../../components/commons/NoRecordFound";
import Pagination from "../../components/commons/Table/Pagination";

export default function AttRomms() {

    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState([]);
    const [attendancesList, setAttendancesList] = useState([]);

    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [search, setSearch] = useState("");
    const [personType, setPersonType] = useState("");
    const [standardType, setStandardType] = useState("");
    const [categoryValue, setCategoryValue] = useState("");

    // AutoComplete

    const [personList, setPersonList] = useState([]);
    const [selectPerson, setSelectPerson] = useState("");

    const [standardList, setStandardList] = useState([]);
    const [standard, setStandard] = useState("");

    const handleCategoryChange = (event) => {
        setCategoryValue(event.target.value);
    };

    const getStandardListHandler = async () => {
        try {
            const { data, message, success } = await getStandardAllList();
            if (success) {
                if (data && data.length > 0) {
                    const standardData = data.map((response, index) => {
                        return {
                            label: response.name,
                            id: response.id,
                        };
                    });
                    setStandardList(standardData);
                }
            } else {
                setStandardList([]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getPersonListHandler = async () => {
        try {
            const { count, data, message, success, totalPage } =
                await newPersonListHandler();
            if (success) {
                const personData =
                    data &&
                    data.map((res) => {
                        return {
                            label: res.username,
                            id: res.id,
                        };
                    });
                setPersonList(personData);
            } else {
                setPersonList([]);
            }
        } catch (err) {
            console.error("error", err);
        }
    };

    useEffect(() => {
        getStandardListHandler();
        getPersonListHandler();
    }, []);

    const pageChangeHandler = (data) => {
        setPage(data);
        getAttendancesListHandler(
            startDate,
            endDate,
            search,
            data,
            categoryValue,
            personType,
            standardType
        );
    };

    const getAttendancesListHandler = async (
        startDate,
        endDate,
        search,
        page,
        categoryValue,
        personType,
        standardType
    ) => {
        try {
            setLoading(true);
            const { count, data, message, success, totalPage, others } =
                await getAttendanceApiRoomListHandler(
                    startDate,
                    endDate,
                    search,
                    page,
                    categoryValue,
                    personType,
                    standardType
                );
            if (success) {
                setDataCount(count);
                setAttendancesList(data);
            } else {
                setDataCount(0);
                setAttendancesList([]);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAttendancesListHandler(
            startDate,
            endDate,
            search,
            page,
            categoryValue,
            personType,
            standardType
        );
    }, [startDate, endDate, page, categoryValue, personType, standardType]);

    return (
        <>
            <Stack flexDirection={"row"} gap={2} mb={2}>
                <Typography className="white-box fontsize" >
                    Date:-
                </Typography>
                <Input
                    placeholder="Select Date"
                    type="date"
                    onChange={(event) => setStartDate(event.target.value)}
                    style={{ width: "300px", height: "100%" }}
                />
                <Typography className="white-box fontsize" >
                    Room:-
                </Typography>
                <Autocomplete
                    freeSolo
                    value={standard}
                    sx={{ width: 300, backgroundColor: "#fff", borderRadius: "10px" }}
                    options={standardList}
                    onChange={(event, value) => {
                        if (value) {
                            setStandard(value);
                            setStandardType(value?.id);
                        } else {
                            setStandard("");
                            setStandardType(value?.id);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={"Select room"} />
                    )}
                />
                {/* <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "20px" }}>
                    SFI:-
                </Typography>
                <Autocomplete
                    freeSolo
                    value={selectPerson}
                    sx={{ width: 300, backgroundColor: "#fff", borderRadius: "10px" }}
                    options={personList}
                    onChange={(event, value) => {
                        if (value) {
                            setSelectPerson(value);
                            setPersonType(value?.id);
                        } else {
                            setSelectPerson("");
                            setPersonType(value?.id);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={"eg.SFI"} />
                    )}
                />

                <Typography className="white-box" style={{ justifyContent: "center", fontWeight: "600", fontSize: "20px" }}>
                    Category:-
                </Typography>
                <Select
                    style={{ maxWidth: "300px" }}
                    selectHead="Select Category"
                    selectList={categoryList}
                    value={categoryValue}
                    onChange={handleCategoryChange}
                /> */}
            </Stack>

            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow className="MuiReportTable-root">
                            <TableCell>SFI</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Entry</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell>Guardian</TableCell>
                            <TableCell>Image</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableSkeleton rowsNum={10} colsNum={13} />
                        ) : attendancesList && attendancesList.length > 0 ? (
                            attendancesList.map((response, index) => {
                                const {
                                    username,
                                    user_image,
                                    date,
                                    entry_time,
                                    exit_time,
                                    guardian_username,
                                    guardian_image
                                } = response;
                                return (
                                    <TableRow
                                        key={index}
                                        className="MuiReportTable-root"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell>{username || "-"}</TableCell>
                                        <TableCell>
                                            <img
                                                src={user_image ? user_image : ""}
                                                alt=""
                                                width={100}
                                                height={100}
                                            />
                                        </TableCell>
                                        <TableCell>{date ? date : "-"}</TableCell>
                                        <TableCell>{entry_time ? entry_time : "-  "}</TableCell>
                                        <TableCell>{exit_time ? exit_time : "-"} </TableCell>
                                        <TableCell>{guardian_username ? guardian_username : "-"}</TableCell>
                                        <TableCell>
                                            <img
                                                src={guardian_image ? guardian_image : ""}
                                                alt=""
                                                width={100}
                                                height={100}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <NoRecordFound colSpan={7} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                pageNumber={page}
                count={dataCount}
                pageChangeHandler={pageChangeHandler}
                perPage={8}
            />
        </>
    )
}