import Pagination from "../../components/commons/Table/Pagination";
import { useStyles } from "../../helper/Theme";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import { Unknown } from "../../helper/Constant";
import { DateRange } from "../../components/commons";
import NoRecordFound from "../../components/commons/NoRecordFound";
import { TableSkeleton } from "../../components/commons/Table";
import { alaramGetApiHandler } from "../../Service/Alaram.service";
import moment from "moment";
import SubHeader from "../../components/commons/SubHeader";

export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [alarmsData, setAlarmsData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);

  const getAllAlaramListHandler = async (startDate, endDate, page) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } =
        await alaramGetApiHandler(startDate, endDate, page);
      if (success) {
        setDataCount(count);
        setAlarmsData(data);
      } else {
        setDataCount(0);
        setAlarmsData([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAlaramListHandler(startDate, endDate, page);
  }, [startDate, endDate, page]);

  const pageChangeHandler = (data) => {
    setPage(data);
  };

  const dataRanageHandler = (value) => {
    setStartDate(value.formDate);
    setEndDate(value.toDate);
  };

  return (
    <>
      <Stack mb={2} flexDirection={"row"} gap={3} alignItems={"center"}>
        <Typography className="white-box fontsize">Date:-</Typography>
        <DateRange dataHandler={dataRanageHandler} />
        {/* <SubHeader Module={"ALARMS"} buttonName={"export as Csv"} /> */}
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className="MuiReportTable-root">
              <TableCell>SFI</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Entry</TableCell>
              <TableCell>Exit</TableCell>
              <TableCell>Linked</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowsNum={10} colsNum={7} />
            ) : alarmsData && alarmsData.length > 0 ? (
              alarmsData.map((response, index) => {
                const {
                  alert_image,
                  alert_type,
                  alert_type_name,
                  blacklisted_person,
                  created_at,
                  device,
                  device_name,
                  location,
                  location_name,
                  entry_time,
                  exit_time,
                  id,
                  master_guardian_profile_image,
                  guardian_profile_image,
                  profile_image,
                  student,
                  usernames,
                } = response;
                return (
                  <TableRow
                    key={index}
                    className="MuiReportTable-root"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{usernames ? usernames : "-"}</TableCell>
                    <TableCell>
                      <img
                        src={profile_image ? profile_image : ""}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </TableCell>
                    <TableCell>
                      {moment(created_at).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{entry_time ? entry_time : "-"}</TableCell>
                    <TableCell>{exit_time ? exit_time : "-"}</TableCell>
                    <TableCell>
                      {master_guardian_profile_image &&
                        master_guardian_profile_image.length > 0 ? (
                        master_guardian_profile_image.map((response, i) => {
                          return (
                            <img
                              key={i}
                              src={response ? response : ""}
                              alt=""
                              width={100}
                              height={100}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <Stack
                        flexDirection={"row"}
                        gap={1}
                        justifyContent={"center"}
                      >
                        <img src={""} alt="" width={100} height={100} />
                        <img src={""} alt="" width={100} height={100} />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {alert_type_name ? alert_type_name : "-"}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound colSpan={7} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={dataCount}
        pageChangeHandler={pageChangeHandler}
        perPage={8}
      />
    </>
  );
}
